<template>
  <v-container fluid v-if="view.public">
    <message-card>
      <h2>No Public view yet</h2>
    </message-card>
  </v-container>
  <v-container fluid v-else-if="!division.hydrated && false">
    <message-card>
      <h2>Loading</h2>
    </message-card>
  </v-container>
  <v-container fluid class="pa-1" v-else>
    <v-row dense justify="center">
      <v-col cols="12">
        <v-expansion-panels
          v-model="panel"
          multiple
        >
          <!-- EXISTING ROUNDS -->
          <v-expansion-panel
            v-for="(round, i) in division.days"
            :key="round.number"
            :id="`round${round.number}`"
            :style="i > 0 ? 'border-top: 1px solid white !important' : null"
            ripple
          >
            <v-expansion-panel-header color="color1 color1Text--text" class="title">
              {{round.name}}
              <template v-slot:actions>
                <v-icon color="color1Text">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pb-0">
              <v-card flat class="pb-0">
                <round-settings
                  :round="round"
                  :fromSelect="fromSelections"
                  :divisionId="division.id"
                  :locked="locked"
                ></round-settings>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- NEW ROUND -->
          <v-expansion-panel
            v-if="newRound"
            :id="`round${newRound.number}`"
            style="border-top: 1px solid white !important"
            ripple
          >
            <v-expansion-panel-header color="color1 color1Text--text" class="title" id="newRound">
              {{newRound.name}}
              <template v-slot:actions>
                <v-icon color="color1Text">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card flat>
                <round-settings
                  :round="newRound"
                  :fromSelect="fromSelections"
                  :divisionId="division.id"
                  @round-created="onCancelNewRound"
                  @cancel-new-round="onCancelNewRound"
                  :edit="true"
                ></round-settings>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- COPIED ROUNDs -->
          <v-expansion-panel
            v-for="(newRound, i) in copiedRounds"
            :id="`round${newRound.number}`"
            :key="`round${newRound.number}`"
            style="border-top: 1px solid white !important"
            ripple
          >
            <v-expansion-panel-header color="color1 color1Text--text" class="title" id="newRound">
              {{newRound.name}}
              <template v-slot:actions>
                <v-icon color="color1Text">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card flat>
                <round-settings
                  :round="newRound"
                  :fromSelect="fromSelections"
                  :divisionId="division.id"
                  @round-created="onCancelCopiedRound(i)"
                  @cancel-new-round="onCancelCopiedRound(i)"
                  :edit="true"
                  :copied="true"
                ></round-settings>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>

    </v-row>
    <v-row dense v-if="!newRound && !locked">
      <v-col cols="12">
        <v-btn color="color3" text @click.stop="addRound">Add Round {{division.days.length + 1}}</v-btn>
        <round-copier
          v-if="user && user.id === 1"
          :tournament="tournament"
          :division="division"
          @copy-us="onCopyUs"
        ></round-copier>
      </v-col>
    </v-row>

    <v-dialog
      v-model="redirectDialog"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-toolbar color="color2 color2Text--text">
          <v-toolbar-title>Oops!</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-3 title">
          You haven't created the pools yet click OK to get started.
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="success" text @click.stop="redirectDialog = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>

</template>

<script>
import RouteMixin from '@/Mixins/RouteMixin'
import Day from '@/classes/TournamentDay'
import TeamSelector from '@/classes/TeamSelector'
import { BracketTypes } from '@/classes/BracketTypes'
import Bracket from '@/classes/Bracket'
import RoundSettings from '@/components/Tournament/RoundSettings/RoundSettings.vue'
import max from 'lodash.max'
import { mapGetters } from 'vuex'
const RoundCopier = () => import('@/components/Tournament/RoundSettings/RoundCopier.vue')

export default {
  mixins: [RouteMixin],
  data () {
    return {
      panel: [0],
      edit: false,
      goAdvanced: false,
      newRound: null,
      redirectDialog: false,
      copiedRounds: []
    }
  },
  computed: {
    ...mapGetters(['tournament', 'getDivision', 'view', 'user']),
    locked () {
      return this.tournament.locked || this.division.complete
    },
    division () {
      return this.getDivision(this.divisionId)
    },
    bracketTypes () {
      return BracketTypes
    },
    lastPoolRound () {
      const i = max(this.division.days.filter(f => f.poolPlay).map(d => +d.number))
      if (i) return this.division.days.find(d => d.number === i)
      return null
    },
    lastRound () {
      const i = max(this.division.days.map(d => +d.number))
      if (i) return this.division.days.find(d => d.number === i)
      return null
    },
    lastRoundEnd () {
      return this.lastRound && this.lastRound.endTime
    },
    nextRoundNumber () {
      return this.lastRound ? this.lastRound.number + 1 : 1
    },
    fromSelections () {
      return this.division.days.map(d => {
        return {
          id: d.id,
          number: d.number,
          poolPlay: d.poolPlay,
          bracketPlay: d.bracketPlay,
          text: `${d.name} Results`,
          i: null
        }
      })
    }
  },
  methods: {
    onCopyUs (rounds) {
      rounds.forEach(r => {
        const dto = new Day(this.$VBL, r.dto)
        dto.id = 0
        dto.brackets.forEach(f => { f.id = 0 })
        dto.teamSelectors.forEach(ts => {
          const tsFromRound = this.tournament.rounds.find(f => f.id === ts.fromRoundId)
          const fromRound = this.division.rounds.find(f => f.number === tsFromRound.number)
          ts.id = 0
          ts.fromRoundId = fromRound.id
        })
        this.copiedRounds.push(dto)
      })
      this.openNew()
    },
    addRound () {
      this.newRound = new Day(this.$VBL, {
        id: 0,
        number: this.nextRoundNumber,
        date: this.lastRound.date,
        checkInTime: this.lastRound.checkInTime,
        playTime: (this.lastPoolRound && this.lastPoolRound.poolPlay && this.lastPoolRound.endTime) || this.lastRound.playTime,
        poolPlay: false,
        bracketPlay: true,
        brackets: [new Bracket(this.$VBL, { type: 'SINGLE_ELIM', props: this.division.dualProp ? [this.division.dualProp, 'avoidMatchups'] : ['avoidMatchups'] })],
        teamSelectors: this.lastPoolRound && this.lastPoolRound.poolPlay ? [new TeamSelector(this.$VBL, { type: 'TOP_N_EACH', fromRoundId: this.lastPoolRound.id })] : null
      })
    },
    onCancelNewRound () {
      this.newRound = null
    },
    onCancelCopiedRound (i) {
      this.copiedRounds.splice(i, 1)
    },
    patch (v) {
      const dto = {}
      dto[v] = this.division[v]

      this.division.patch(dto)
    },
    openAll () {
      if (this.division && this.division.days && this.division.days.length < 3) {
        this.panel = this.division.days.map((m, i) => i)
      } else {
        this.panel = []
      }
    },
    openNew () {
      this.panel = []
      if (this.division && this.division.days) {
        this.division.days.forEach((d, i) => {
          if (d.Id === 0) {
            this.panel.push(i)
          }
        })
      }
      const x = this.copiedRounds.length
      const y = this.division.days.length
      for (let i = 0; i < x; i++) {
        this.panel.push((y + i))
      }
    },
    goToNewRound () {
      this.$vuetify.goTo('#newRound', {
        duration: 300,
        offset: 50,
        easing: 'easeInOutCubic'
      })
    }
  },
  watch: {
    newRound: function (val) {
      this.panel = [this.division.days.length]
      this.$nextTick(() => {
        setTimeout(this.goToNewRound, 333)
      })
    },
    division: function (val) {
      this.onCancelNewRound()
      const clone = JSON.stringify(this.panel)
      this.$nextTick(() => {
        this.panel = JSON.parse(clone)
      })
    },
    'division.showTeams': function (val) {
      this.patch('showTeams')
    },
    'division.disableRegistration': function (val) {
      this.patch('disableRegistration')
    },
    '$route.params': {
      handler: function (val) {
        console.log(val.replacement)
      },
      deep: true
    }
  },
  components: {
    RoundSettings,
    RoundCopier
  },
  mounted () {
    this.openAll()
    // this.redirectDialog = this.$route.params.replacement
  }
}
</script>

<style scoped>
</style>
